@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");
@import url(icons/font-awesome/css/fontawesome-all.css);
@import url(icons/simple-line-icons/css/simple-line-icons.css);
@import url(icons/themify-icons/themify-icons.css);
.topbar .dropdown-menu .with-arrow > span {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #5f76e8;
  --indigo: #6610f2;
  --purple: #5f76e8;
  --pink: #e83e8c;
  --red: #ff4f70;
  --orange: #fb8c00;
  --yellow: #fdc16a;
  --green: #22ca80;
  --teal: #20c997;
  --cyan: #01caf1;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #1c2d41;
  --blue: #5f76e8;
  --indigo: #6610f2;
  --purple: #5f76e8;
  --pink: #e83e8c;
  --red: #ff4f70;
  --orange: #fb8c00;
  --yellow: #fdc16a;
  --green: #22ca80;
  --teal: #20c997;
  --cyan: #01caf1;
  --white: #fff;
  --gray: #6c757d;
  --primary: #5f76e8;
  --secondary: #6c757d;
  --success: #22ca80;
  --info: #5f76e8;
  --warning: #fdc16a;
  --danger: #ff4f70;
  --light: #e8eaec;
  --dark: #1c2d41;
  --cyan: #01caf1;
  --orange: #fb8c00;
  --purple: #5f76e8;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Rubik", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7c8798;
  text-align: left;
  background-color: #f9fbfd;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #5f76e8;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1f3fdb;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #9eabc0;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
  /* display: none; */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
  font-size: 1.875rem;
}

h3,
.h3 {
  font-size: 1.3125rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f9fbfd;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 1px;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #7c8798;
}
.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e8eef3;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8eef3;
}
.table tbody + tbody {
  border-top: 2px solid #e8eef3;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e8eef3;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e8eef3;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f4f8fb;
}

.table-hover tbody tr:hover {
  color: #7c8798;
  background-color: #e8eaec;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dfe4fa;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #acb8f3;
}

.table-hover .table-primary:hover {
  background-color: #c9d1f7;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c9d1f7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e2e3e5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #d5d6d9;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d5d6d9;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d3f4e6;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8ce3bd;
}

.table-hover .table-success:hover {
  background-color: #bfefda;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bfefda;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dfe4fa;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #acb8f3;
}

.table-hover .table-info:hover {
  background-color: #c9d1f7;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c9d1f7;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff3e1;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fedfb2;
}

.table-hover .table-warning:hover {
  background-color: #ffe9c8;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe9c8;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffdce2;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa3b5;
}

.table-hover .table-danger:hover {
  background-color: #ffc3cd;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffc3cd;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fafbfb;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f3f4f5;
}

.table-hover .table-light:hover {
  background-color: #ecf0f0;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ecf0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d2d5d9;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #89929c;
}

.table-hover .table-dark:hover {
  background-color: #c4c8cd;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c4c8cd;
}

.table-cyan,
.table-cyan > th,
.table-cyan > td {
  background-color: #ccf4fc;
}

.table-cyan th,
.table-cyan td,
.table-cyan thead th,
.table-cyan tbody + tbody {
  border-color: #7be3f8;
}

.table-hover .table-cyan:hover {
  background-color: #b4effb;
}
.table-hover .table-cyan:hover > td,
.table-hover .table-cyan:hover > th {
  background-color: #b4effb;
}

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #fee8cc;
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #fdc37a;
}

.table-hover .table-orange:hover {
  background-color: #feddb3;
}
.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #feddb3;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #dfe4fa;
}

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #acb8f3;
}

.table-hover .table-purple:hover {
  background-color: #c9d1f7;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #c9d1f7;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #e8eaec;
}

.table-hover .table-active:hover {
  background-color: #dadde0;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #dadde0;
}

.table .thead-dark th {
  color: #fff;
  background-color: #1c2d41;
  border-color: #28405c;
}

.table .thead-light th {
  color: #4f5467;
  background-color: #e9ecef;
  border-color: #e8eef3;
}

.table-dark {
  color: #fff;
  background-color: #1c2d41;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #28405c;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #4f5467;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: transparent;
}
.form-control::placeholder {
  color: #b8c3d5;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #4f5467;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #7c8798;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #9eabc0;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #22ca80;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(34, 202, 128, 0.9);
  border-radius: 2px;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #22ca80;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2322ca80' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #22ca80;
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #22ca80;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url(../../assets/images/custom-select.png) no-repeat right 0.75rem
      center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2322ca80' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #22ca80;
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #22ca80;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #22ca80;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #22ca80;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #40df99;
  background-color: #40df99;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #22ca80;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #22ca80;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #22ca80;
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff4f70;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 79, 112, 0.9);
  border-radius: 2px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #ff4f70;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff4f70' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff4f70' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ff4f70;
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ff4f70;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url(../../assets/images/custom-select.png) no-repeat right 0.75rem
      center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff4f70' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23ff4f70' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ff4f70;
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff4f70;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff4f70;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ff4f70;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff8299;
  background-color: #ff8299;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #ff4f70;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff4f70;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff4f70;
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #7c8798;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #7c8798;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: transparent;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-primary:hover {
  color: #fff;
  background-color: #3e59e3;
  border-color: #3250e2;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 139, 235, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3250e2;
  border-color: #2746e0;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 139, 235, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #22ca80;
  border-color: #22ca80;
}
.btn-success:hover {
  color: #fff;
  background-color: #1ca96b;
  border-color: #1b9e64;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 210, 147, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #22ca80;
  border-color: #22ca80;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1b9e64;
  border-color: #19935d;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 210, 147, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-info:hover {
  color: #fff;
  background-color: #3e59e3;
  border-color: #3250e2;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 139, 235, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3250e2;
  border-color: #2746e0;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 139, 235, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fdc16a;
  border-color: #fdc16a;
}
.btn-warning:hover {
  color: #212529;
  background-color: #fcb144;
  border-color: #fcac38;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 170, 96, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #fdc16a;
  border-color: #fdc16a;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #fcac38;
  border-color: #fca72b;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 170, 96, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ff4f70;
  border-color: #ff4f70;
}
.btn-danger:hover {
  color: #fff;
  background-color: #ff2951;
  border-color: #ff1c47;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 133, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #ff4f70;
  border-color: #ff4f70;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1c47;
  border-color: #ff0f3c;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 133, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #e8eaec;
  border-color: #e8eaec;
}
.btn-light:hover {
  color: #212529;
  background-color: #d3d7db;
  border-color: #ccd1d5;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 204, 207, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #e8eaec;
  border-color: #e8eaec;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #ccd1d5;
  border-color: #c5cacf;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 204, 207, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1c2d41;
  border-color: #1c2d41;
}
.btn-dark:hover {
  color: #fff;
  background-color: #101a26;
  border-color: #0d141d;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 77, 94, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #1c2d41;
  border-color: #1c2d41;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d141d;
  border-color: #090e14;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 77, 94, 0.5);
}

.btn-cyan {
  color: #fff;
  background-color: #01caf1;
  border-color: #01caf1;
}
.btn-cyan:hover {
  color: #fff;
  background-color: #01aacb;
  border-color: #019fbe;
}
.btn-cyan:focus,
.btn-cyan.focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 210, 243, 0.5);
}
.btn-cyan.disabled,
.btn-cyan:disabled {
  color: #fff;
  background-color: #01caf1;
  border-color: #01caf1;
}
.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled).active,
.show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #019fbe;
  border-color: #0195b2;
}
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 210, 243, 0.5);
}

.btn-orange {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.btn-orange:hover {
  color: #fff;
  background-color: #d57700;
  border-color: #c87000;
}
.btn-orange:focus,
.btn-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 125, 6, 0.5);
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c87000;
  border-color: #bb6800;
}
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 125, 6, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-purple:hover {
  color: #fff;
  background-color: #3e59e3;
  border-color: #3250e2;
}
.btn-purple:focus,
.btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 139, 235, 0.5);
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #3250e2;
  border-color: #2746e0;
}
.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 139, 235, 0.5);
}

.btn-outline-primary {
  color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #5f76e8;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #22ca80;
  border-color: #22ca80;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #22ca80;
  border-color: #22ca80;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #22ca80;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #22ca80;
  border-color: #22ca80;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.5);
}

.btn-outline-info {
  color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #5f76e8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}

.btn-outline-warning {
  color: #fdc16a;
  border-color: #fdc16a;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #fdc16a;
  border-color: #fdc16a;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 193, 106, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fdc16a;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fdc16a;
  border-color: #fdc16a;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 193, 106, 0.5);
}

.btn-outline-danger {
  color: #ff4f70;
  border-color: #ff4f70;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff4f70;
  border-color: #ff4f70;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ff4f70;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff4f70;
  border-color: #ff4f70;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.5);
}

.btn-outline-light {
  color: #e8eaec;
  border-color: #e8eaec;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #e8eaec;
  border-color: #e8eaec;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 234, 236, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #e8eaec;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e8eaec;
  border-color: #e8eaec;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 234, 236, 0.5);
}

.btn-outline-dark {
  color: #1c2d41;
  border-color: #1c2d41;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #1c2d41;
  border-color: #1c2d41;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 45, 65, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #1c2d41;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c2d41;
  border-color: #1c2d41;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 45, 65, 0.5);
}

.btn-outline-cyan {
  color: #01caf1;
  border-color: #01caf1;
}
.btn-outline-cyan:hover {
  color: #fff;
  background-color: #01caf1;
  border-color: #01caf1;
}
.btn-outline-cyan:focus,
.btn-outline-cyan.focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 202, 241, 0.5);
}
.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
  color: #01caf1;
  background-color: transparent;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled).active,
.show > .btn-outline-cyan.dropdown-toggle {
  color: #fff;
  background-color: #01caf1;
  border-color: #01caf1;
}
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 202, 241, 0.5);
}

.btn-outline-orange {
  color: #fb8c00;
  border-color: #fb8c00;
}
.btn-outline-orange:hover {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.btn-outline-orange:focus,
.btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}
.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #fb8c00;
  background-color: transparent;
}
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-outline-purple {
  color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-purple:focus,
.btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}
.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #5f76e8;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #5f76e8;
  text-decoration: none;
}
.btn-link:hover {
  color: #1f3fdb;
  text-decoration: none;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #7c8798;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #e9ecef;
  border-radius: 2px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e8eaec;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.65rem 1rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e8eaec;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #5f76e8;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.65rem 1rem;
  color: #6c757d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5467;
  text-align: center;
  white-space: nowrap;
  background-color: #e8eaec;
  border: 1px solid #e9ecef;
  border-radius: 2px;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #5f76e8;
  background-color: #5f76e8;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: transparent;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: black;
  border-color: black;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #b8c3d5 solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #5f76e8;
  background-color: #5f76e8;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(95, 118, 232, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(95, 118, 232, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(95, 118, 232, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 1px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #b8c3d5;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(95, 118, 232, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #b8c3d5;
  vertical-align: middle;
  background: url(../../assets/images/custom-select.png) no-repeat right 0.75rem
    center/8px 10px;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  appearance: none;
}
.custom-select:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: transparent;
}
.custom-select:focus::-ms-value {
  color: #4f5467;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(0, 0, 0, 0.25);
  box-shadow: transparent;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 2px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #4f5467;
  content: "Browse";
  background-color: #e8eaec;
  border-left: inherit;
  border-radius: 0 2px 2px 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f9fbfd, transparent;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f9fbfd, transparent;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f9fbfd, transparent;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #5f76e8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #fbfcfe;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #5f76e8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #fbfcfe;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #5f76e8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #fbfcfe;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #b8c3d5;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #b8c3d5;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #b8c3d5;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4f5467;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5f76e8;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #b8c3d5;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #1c2d41;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: #b8c3d5;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23b8c3d5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #b8c3d5;
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #e9ecef;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 25px;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 25px;
}

.card-header {
  padding: 0.75rem 25px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid #e9ecef;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 25px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid #e9ecef;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 0) calc(0.25rem - 0);
}

.card-header-tabs {
  margin-right: -12.5px;
  margin-bottom: -0.75rem;
  margin-left: -12.5px;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -12.5px;
  margin-left: -12.5px;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 0);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 0);
  border-top-right-radius: calc(0.25rem - 0);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 0);
  border-bottom-left-radius: calc(0.25rem - 0);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  background-color: #f9fbfd;
  border-radius: 2px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #5f76e8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1f3fdb;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: transparent;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.badge {
  display: inline-block;
  padding: 4px 10px;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #5f76e8;
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #3250e2;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #22ca80;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1b9e64;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 202, 128, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #5f76e8;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #3250e2;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fdc16a;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #fcac38;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 193, 106, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ff4f70;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ff1c47;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 79, 112, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #e8eaec;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #ccd1d5;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(232, 234, 236, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1c2d41;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #0d141d;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 45, 65, 0.5);
}

.badge-cyan {
  color: #fff;
  background-color: #01caf1;
}
a.badge-cyan:hover,
a.badge-cyan:focus {
  color: #fff;
  background-color: #019fbe;
}
a.badge-cyan:focus,
a.badge-cyan.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(1, 202, 241, 0.5);
}

.badge-orange {
  color: #212529;
  background-color: #fb8c00;
}
a.badge-orange:hover,
a.badge-orange:focus {
  color: #212529;
  background-color: #c87000;
}
a.badge-orange:focus,
a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #5f76e8;
}
a.badge-purple:hover,
a.badge-purple:focus {
  color: #fff;
  background-color: #3250e2;
}
a.badge-purple:focus,
a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(95, 118, 232, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 2px;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 2px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #313d79;
  background-color: #dfe4fa;
  border-color: #d2d9f9;
}
.alert-primary hr {
  border-top-color: #bcc6f6;
}
.alert-primary .alert-link {
  color: #222b55;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #126943;
  background-color: #d3f4e6;
  border-color: #c1f0db;
}
.alert-success hr {
  border-top-color: #acebcf;
}
.alert-success .alert-link {
  color: #0b3d27;
}

.alert-info {
  color: #313d79;
  background-color: #dfe4fa;
  border-color: #d2d9f9;
}
.alert-info hr {
  border-top-color: #bcc6f6;
}
.alert-info .alert-link {
  color: #222b55;
}

.alert-warning {
  color: #846437;
  background-color: #fff3e1;
  border-color: #feeed5;
}
.alert-warning hr {
  border-top-color: #fde4bc;
}
.alert-warning .alert-link {
  color: #604928;
}

.alert-danger {
  color: #85293a;
  background-color: #ffdce2;
  border-color: #ffced7;
}
.alert-danger hr {
  border-top-color: #ffb5c2;
}
.alert-danger .alert-link {
  color: #5e1d29;
}

.alert-light {
  color: #797a7b;
  background-color: #fafbfb;
  border-color: #f9f9fa;
}
.alert-light hr {
  border-top-color: #ebebee;
}
.alert-light .alert-link {
  color: #606161;
}

.alert-dark {
  color: #0f1722;
  background-color: #d2d5d9;
  border-color: #bfc4ca;
}
.alert-dark hr {
  border-top-color: #b1b7be;
}
.alert-dark .alert-link {
  color: black;
}

.alert-cyan {
  color: #01697d;
  background-color: #ccf4fc;
  border-color: #b8f0fb;
}
.alert-cyan hr {
  border-top-color: #a0ebfa;
}
.alert-cyan .alert-link {
  color: #013f4a;
}

.alert-orange {
  color: #834900;
  background-color: #fee8cc;
  border-color: #fedfb8;
}
.alert-orange hr {
  border-top-color: #fed49f;
}
.alert-orange .alert-link {
  color: #502d00;
}

.alert-purple {
  color: #313d79;
  background-color: #dfe4fa;
  border-color: #d2d9f9;
}
.alert-purple hr {
  border-top-color: #bcc6f6;
}
.alert-purple .alert-link {
  color: #222b55;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5f76e8;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #4f5467;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #4f5467;
  text-decoration: none;
  background-color: #e8eaec;
}
.list-group-item-action:active {
  color: #7c8798;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #5f76e8;
  border-color: #5f76e8;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #313d79;
  background-color: #d2d9f9;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #313d79;
  background-color: #bcc6f6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #313d79;
  border-color: #313d79;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #126943;
  background-color: #c1f0db;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #126943;
  background-color: #acebcf;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #126943;
  border-color: #126943;
}

.list-group-item-info {
  color: #313d79;
  background-color: #d2d9f9;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #313d79;
  background-color: #bcc6f6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #313d79;
  border-color: #313d79;
}

.list-group-item-warning {
  color: #846437;
  background-color: #feeed5;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #846437;
  background-color: #fde4bc;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #846437;
  border-color: #846437;
}

.list-group-item-danger {
  color: #85293a;
  background-color: #ffced7;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #85293a;
  background-color: #ffb5c2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #85293a;
  border-color: #85293a;
}

.list-group-item-light {
  color: #797a7b;
  background-color: #f9f9fa;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #797a7b;
  background-color: #ebebee;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #797a7b;
  border-color: #797a7b;
}

.list-group-item-dark {
  color: #0f1722;
  background-color: #bfc4ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0f1722;
  background-color: #b1b7be;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0f1722;
  border-color: #0f1722;
}

.list-group-item-cyan {
  color: #01697d;
  background-color: #b8f0fb;
}
.list-group-item-cyan.list-group-item-action:hover,
.list-group-item-cyan.list-group-item-action:focus {
  color: #01697d;
  background-color: #a0ebfa;
}
.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #01697d;
  border-color: #01697d;
}

.list-group-item-orange {
  color: #834900;
  background-color: #fedfb8;
}
.list-group-item-orange.list-group-item-action:hover,
.list-group-item-orange.list-group-item-action:focus {
  color: #834900;
  background-color: #fed49f;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #834900;
  border-color: #834900;
}

.list-group-item-purple {
  color: #313d79;
  background-color: #d2d9f9;
}
.list-group-item-purple.list-group-item-action:hover,
.list-group-item-purple.list-group-item-action:focus {
  color: #313d79;
  background-color: #bcc6f6;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #313d79;
  border-color: #313d79;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #edf2f9;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #edf2f9;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 2px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 2px;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 2px 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 2px 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(2px - 1px);
  border-top-right-radius: calc(2px - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #7c8798;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #5f76e8 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3250e2 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #22ca80 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1b9e64 !important;
}

.bg-info {
  background-color: #5f76e8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3250e2 !important;
}

.bg-warning {
  background-color: #fdc16a !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fcac38 !important;
}

.bg-danger {
  background-color: #ff4f70 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff1c47 !important;
}

.bg-light {
  background-color: #e8eaec !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #ccd1d5 !important;
}

.bg-dark {
  background-color: #1c2d41 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0d141d !important;
}

.bg-cyan {
  background-color: #01caf1 !important;
}

a.bg-cyan:hover,
a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #019fbe !important;
}

.bg-orange {
  background-color: #fb8c00 !important;
}

a.bg-orange:hover,
a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #c87000 !important;
}

.bg-purple {
  background-color: #5f76e8 !important;
}

a.bg-purple:hover,
a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #3250e2 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #edf2f9 !important;
}

.border-top {
  border-top: 1px solid #edf2f9 !important;
}

.border-right {
  border-right: 1px solid #edf2f9 !important;
}

.border-bottom {
  border-bottom: 1px solid #edf2f9 !important;
}

.border-left {
  border-left: 1px solid #edf2f9 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #5f76e8 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #22ca80 !important;
}

.border-info {
  border-color: #5f76e8 !important;
}

.border-warning {
  border-color: #fdc16a !important;
}

.border-danger {
  border-color: #ff4f70 !important;
}

.border-light {
  border-color: #e8eaec !important;
}

.border-dark {
  border-color: #1c2d41 !important;
}

.border-cyan {
  border-color: #01caf1 !important;
}

.border-orange {
  border-color: #fb8c00 !important;
}

.border-purple {
  border-color: #5f76e8 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 1px !important;
}

.rounded {
  border-radius: 2px !important;
}

.rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.rounded-right {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.rounded-left {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.rounded-lg {
  border-radius: 2px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0px 2px 9px 0px rgba(169, 184, 200, 0.2) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-30 {
    height: 30% !important;
  }
  .h-sm-35 {
    height: 35% !important;
  }
  .h-sm-40 {
    height: 40% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .h-md-30 {
    height: 30% !important;
  }
  .h-md-35 {
    height: 35% !important;
  }
  .h-md-40 {
    height: 40% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-30 {
    height: 30% !important;
  }
  .h-lg-35 {
    height: 35% !important;
  }
  .h-lg-40 {
    height: 40% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .h-xl-30 {
    height: 30% !important;
  }
  .h-xl-35 {
    height: 35% !important;
  }
  .h-xl-40 {
    height: 40% !important;
  }
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #5f76e8 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #1f3fdb !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #22ca80 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #178957 !important;
}

.text-info {
  color: #5f76e8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #1f3fdb !important;
}

.text-warning {
  color: #fdc16a !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fca21f !important;
}

.text-danger {
  color: #ff4f70 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ff0332 !important;
}

.text-light {
  color: #e8eaec !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #bec4c9 !important;
}

.text-dark {
  color: #1c2d41 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #05080c !important;
}

.text-cyan {
  color: #01caf1 !important;
}

a.text-cyan:hover,
a.text-cyan:focus {
  color: #018aa5 !important;
}

.text-orange {
  color: #fb8c00 !important;
}

a.text-orange:hover,
a.text-orange:focus {
  color: #af6100 !important;
}

.text-purple {
  color: #5f76e8 !important;
}

a.text-purple:hover,
a.text-purple:focus {
  color: #1f3fdb !important;
}

.text-body {
  color: #7c8798 !important;
}

.text-muted {
  color: #9eabc0 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #b8c3d5;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e8eef3;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e8eef3;
  }
}

/*============================================================== 
 For all pages 
 ============================================================== */
#main-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.page-wrapper {
  background: #f9fbfd;
  position: relative;
  transition: 0.2s ease-in;
  display: none;
  box-shadow: 0px 3px 9px 0px rgba(162, 176, 190, 0.15);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.page-wrapper > .container-fluid {
  padding: 35px;
  min-height: calc(100vh - 210px);
}

/*******************
 Footer
*******************/
.footer {
  padding: 15px 20px;
}

/*******************
 Stickey inner-left-part
*******************/
.left-part {
  height: 100%;
  width: 260px;
  position: absolute;
  border-right: 1px solid #edf2f9;
}
.left-part.fixed-left-part {
  position: fixed;
  top: 0px;
  padding-top: 10px;
}
.left-part .show-left-part {
  position: absolute;
  top: 45%;
  right: -41px;
}

.right-part {
  width: calc(100% - 260px);
  min-height: calc(100vh - 64px);
  overflow: auto;
  margin-left: 260px;
}

.reverse-mode .left-part {
  right: 0;
  border-left: 1px solid #edf2f9;
}

.reverse-mode .show-left-part {
  right: auto;
  left: -41px;
}

.reverse-mode .right-part {
  margin-left: 0px;
  margin-right: 260px;
}

/*******************
 Vertical layout
*******************/
#main-wrapper[data-layout="vertical"] {
  /*Vertical*/
  /*sidebar type*/
  /*Sidebar position*/
  /*Header position*/
  /*Boxed Layout*/
}
#main-wrapper[data-layout="vertical"] .topbar .top-navbar .navbar-header {
  transition: 0.2s ease-in;
}
#main-wrapper[data-layout="vertical"][data-sidebartype="full"] .page-wrapper {
  margin-left: 260px;
}
#main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"]
  .topbar
  .top-navbar
  .navbar-header {
  position: fixed;
  z-index: 10;
}
#main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"]
  .left-sidebar {
  position: fixed;
}
#main-wrapper[data-layout="vertical"][data-header-position="fixed"] .topbar {
  position: fixed;
  width: 100%;
}
#main-wrapper[data-layout="vertical"][data-header-position="fixed"]
  .page-wrapper {
  padding-top: 80px;
}
#main-wrapper[data-layout="vertical"][data-boxed-layout="boxed"] {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
}
#main-wrapper[data-layout="vertical"][data-boxed-layout="boxed"][data-header-position="fixed"]
  .topbar {
  max-width: 1200px;
}

/*******************
 Vertical responsive layout
*******************/
@media (min-width: 768px) {
  #main-wrapper[data-layout="vertical"] {
    /*Vertical*/
    /*Sidebar position*/
    /*Vertical with mini-sidebar*/
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="full"]
    .topbar
    .top-navbar
    .navbar-header {
    width: 260px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="full"]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 260px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-collapse {
    margin-left: 65px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header {
    width: 65px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header
    .navbar-brand {
    padding: 0px 8px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header
    .logo-text {
    display: none;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header.expand-logo {
    width: 260px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header.expand-logo
    .logo-text,
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .topbar
    .top-navbar
    .navbar-header.expand-logo
    .logo-icon {
    display: inline-block;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .page-wrapper {
    margin-left: 65px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    .hide-menu,
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    .has-arrow:after {
    display: none;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    .nav-small-cap {
    justify-content: center;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .list-divider {
    margin: 10px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link {
    padding: 12px 15px;
    margin-right: 0;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link
    .feather-icon,
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link
    i {
    margin-right: 0;
    margin-left: 7px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item.active
    .sidebar-link::before {
    display: none;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar {
    width: 65px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover {
    width: 260px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .list-divider {
    margin: 10px 0 20px 30px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link {
    margin-right: 17px;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link
    .feather-icon,
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .sidebar-link
    i {
    margin-right: 8px;
    margin-left: 0;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item.active
    .sidebar-link::before {
    display: block;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    .hide-menu,
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    .has-arrow:after {
    display: block;
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
    .left-sidebar:hover
    .sidebar-nav
    .nav-small-cap {
    justify-content: flex-start;
  }
}

@media (max-width: 767.98px) {
  #main-wrapper {
    /*Header position*/
  }
  #main-wrapper[data-sidebartype="mini-sidebar"] .left-sidebar {
    left: -260px;
  }
  #main-wrapper.show-sidebar .left-sidebar {
    left: 0;
  }
  #main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"]
    .topbar
    .top-navbar
    .navbar-collapse {
    position: relative;
    top: 80px;
  }
  #main-wrapper[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"]
    .topbar
    .top-navbar
    .navbar-collapse {
    position: relative;
    top: 80px;
    background: #fff;
  }
  .left-part {
    left: -260px;
    background: #f9fbfd;
    z-index: 1;
    position: fixed;
    transition: 0.1s ease-in;
  }
  .left-part.show-panel {
    left: 0px;
  }
  .reverse-mode .left-part {
    right: -260px;
    left: auto;
  }
  .reverse-mode .left-part.show-panel {
    right: 0px;
  }
  .right-part {
    width: 100%;
    margin-left: 0px;
  }
}

/*******************
Preloader
********************/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}
.lds-ripple .lds-pos {
  position: absolute;
  border: 2px solid #2962ff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.1, 0.5, 1) infinite;
}
.lds-ripple .lds-pos:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/*******************
 General Topbar
*******************/
.topbar {
  position: relative;
  z-index: 50;
  transition: 0.2s ease-in;
  background: transparent;
}
.topbar .navbar-collapse {
  padding: 0px 10px 0 0;
  border-bottom: 1px solid #edf2f9;
}
.topbar .top-navbar {
  padding: 0px;
  min-height: 80px;
}
.topbar .top-navbar .navbar-header {
  line-height: 80px;
  background: transparent;
  box-shadow: 0 -7px 9px 0 rgba(169, 184, 200, 0.15);
  -webkit-box-shadow: 0 -7px 9px 0 rgba(169, 184, 200, 0.15);
  -moz-box-shadow: 0 -7px 9px 0 rgba(169, 184, 200, 0.15);
}
.topbar .top-navbar .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  padding: 0 30px 0 30px;
  height: 80px;
}
.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
  display: none;
}
.topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
  margin-right: 5px;
}
.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  padding: 0px 15px;
  font-size: 1rem;
  line-height: 80px;
}
.topbar .top-navbar .navbar-nav > .nav-item > .nav-link .notify-no {
  position: absolute;
  top: 17px;
  right: 4px;
  line-height: 11px;
  padding: 4px 6px;
}
.topbar .top-navbar .navbar-nav > .nav-item {
  transition: 0.2s ease-in;
}
.topbar .mailbox .message-center .message-item:hover {
  background: #e8eaec;
}
.topbar .mailbox .message-center .message-item .message-title {
  color: #212529;
}
.topbar .user-dd {
  min-width: 280px;
}
.topbar .nav-toggler,
.topbar .topbartoggler {
  color: #fff;
  padding: 0 15px;
}
.topbar .customize-input {
  position: relative;
}
.topbar .customize-input .form-control {
  padding: 13px 43px 13px 28px;
  color: #b8c3d5;
  display: inline-block;
  line-height: 13px;
}
.topbar .customize-input .form-control-icon {
  position: absolute;
  top: 30px;
  right: 14px;
  height: 20px;
  width: 20px;
}

/*******************
Designed dropdown
******************/
.topbar .dropdown-menu {
  padding-top: 0px;
  border: 0px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}
.topbar .dropdown-menu .with-arrow {
  position: absolute;
  height: 10px;
  overflow: hidden;
  width: 40px;
  top: -10px;
}
.topbar .dropdown-menu .with-arrow > span {
  background-color: #fff;
  width: 15px;
  height: 15px;
  top: 3px;
  left: 15px;
  position: absolute;
  content: "";
  -moz-border-radius: 6px 0 0 0;
  border-radius: 6px 0 0 0;
}
.topbar .dropdown-menu.dropdown-menu-right .with-arrow {
  right: 0px;
}
.topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
  right: 20px;
  left: auto;
}

.topbar .dropdown-toggle::after {
  display: none;
}

/*******************
Topbar Responsive
******************/
@media (max-width: 767.98px) {
  .topbar .top-navbar .navbar-collapse.show,
  .topbar .top-navbar .navbar-collapse.collapsing {
    display: block;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .topbar .top-navbar .navbar-nav {
    flex-direction: row;
  }
  .topbar .top-navbar .navbar-nav .dropdown {
    position: static;
  }
  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding: 0 10px;
  }
  .topbar .top-navbar .navbar-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .topbar .top-navbar .dropdown-menu {
    position: absolute;
    width: 100%;
  }
  .topbar .top-navbar .dropdown-menu .with-arrow {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .mailbox .message-center {
    height: 145px !important;
  }
}

/*******************
Main sidebar
******************/
.left-sidebar {
  position: absolute;
  width: 260px;
  height: 100%;
  top: 0px;
  z-index: 10;
  padding-top: 80px;
  background: transparent;
  transition: 0.2s ease-in;
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  -webkit-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: 100%;
  position: relative;
}

.sidebar-shadow {
  box-shadow: 0px 3px 9px 0px rgba(162, 176, 190, 0.15);
  -webkit-box-shadow: 0px 3px 9px 0px rgba(162, 176, 190, 0.15);
  -moz-box-shadow: 0px 3px 9px 0px rgba(162, 176, 190, 0.15);
}

.sidebar-nav {
  padding-top: 30px;
}
.sidebar-nav #sidebarnav {
  /*******************
         Small cap
         ******************/
}
.sidebar-nav #sidebarnav .list-divider {
  height: 1px;
  background: #818181;
  display: block;
  margin: 10px 0 20px 30px;
  opacity: 0.1;
}
.sidebar-nav #sidebarnav .sidebar-item {
  /*******************
            First level
            *****************/
}
.sidebar-nav #sidebarnav .sidebar-item .sidebar-link {
  color: #fff;
  font-size: 16px;
  padding: 12px 30px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  line-height: 27px;
  opacity: 0.7;
  margin-right: 17px;
}
.sidebar-nav #sidebarnav .sidebar-item .sidebar-link .feather-icon,
.sidebar-nav #sidebarnav .sidebar-item .sidebar-link i {
  font-style: normal;
  height: 20px;
  width: 20px;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-right: 8px;
}
.sidebar-nav #sidebarnav .sidebar-item .sidebar-link.active,
.sidebar-nav #sidebarnav .sidebar-item .sidebar-link:hover {
  opacity: 1;
}
.sidebar-nav #sidebarnav .sidebar-item .sidebar-link .hide-menu {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar-nav #sidebarnav .sidebar-item.selected > .sidebar-link {
  border-radius: 0px 60px 60px 0px;
  color: #fff !important;
  background: linear-gradient(
    to right,
    #8971ea,
    #7f72ea,
    #7574ea,
    #6a75e9,
    #5f76e8
  );
  box-shadow: 0px 7px 12px 0px rgba(95, 118, 232, 0.21);
  opacity: 1;
}
.sidebar-nav #sidebarnav .sidebar-item.selected > .sidebar-link .feather-icon,
.sidebar-nav #sidebarnav .sidebar-item.selected > .sidebar-link i {
  color: #fff;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item.selected
  > .sidebar-link
  .has-arrow::after {
  border-color: #fff;
}
.sidebar-nav #sidebarnav .sidebar-item .first-level {
  padding: 0 0 10px 0;
}
.sidebar-nav #sidebarnav .sidebar-item .first-level .nav-small-cap {
  font-size: 12px;
  font-weight: 500;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item
  .first-level
  .sidebar-item.active
  .sidebar-link {
  position: relative;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item
  .first-level
  .sidebar-item.active
  .sidebar-link::before {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 12px;
  position: absolute;
  left: 0;
  top: 23px;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item
  .first-level
  .sidebar-item.active
  .sidebar-link.active {
  color: #fff;
  font-weight: 500;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item
  .first-level
  .sidebar-item.active
  + .base-level-line {
  display: block;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item
  .first-level
  .sidebar-item
  .sidebar-link {
  padding: 12px 15px;
}
.sidebar-nav
  #sidebarnav
  .sidebar-item
  .first-level
  .sidebar-item
  .sidebar-link
  i {
  font-size: 20px;
  line-height: 20px;
}
.sidebar-nav #sidebarnav .nav-small-cap {
  font-size: 12px;
  padding: 0 30px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 30px;
  color: #fff;
  opacity: 1;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 500;
}
.sidebar-nav #sidebarnav .nav-small-cap i {
  line-height: 30px;
  margin: 0 5px;
}
.sidebar-nav .base-level-line {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  margin-left: 40px;
  display: none;
}

/*******************
 common m-p removed
******************/
.sidebar-nav ul {
  margin: 0px;
  padding: 0px;
}
.sidebar-nav ul li {
  list-style: none;
}

.collapse.in {
  display: block;
}

/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
}
.sidebar-nav .has-arrow::after {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #fff;
  margin-left: 10px;
  -webkit-transform: rotate(223deg) translate(0, -50%);
  -ms-transform: rotate(223deg) translate(0, -50%);
  -o-transform: rotate(223deg) translate(0, -50%);
  transform: rotate(223deg) translate(0, -50%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  top: 27px;
  right: 15px;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(44deg) translate(0, -50%);
  -ms-transform: rotate(44deg) translate(0, -50%);
  -o-transform: rotate(44deg) translate(0, -50%);
  transform: rotate(44deg) translate(0, -50%);
}

.sidebar-nav li.selected > .has-arrow::after,
.sidebar-nav li.selected > .has-arrow.active::after {
  border-color: #fff;
}

/*******************
User Profile
******************/
.user-profile {
  color: #fff;
}
.user-profile a:not(.dropdown-item) {
  color: #fff;
}
.user-profile .badge-no {
  position: absolute;
  top: 20px;
  right: 29px;
  line-height: 13px;
  padding: 2px 5px;
  font-weight: 300;
}
.user-profile .menubar-height {
  line-height: 48px;
}
.user-profile .profile-pic {
  /* background-image: url(../../assets/images/users/profile-pic-2.jpg); */
  width: 60px;
  height: 60px;
  background-position: center center;
  background-repeat: no-repeat;
}
.user-profile .profile-pic .profile-dd {
  position: absolute;
  right: -3px;
  top: 39px;
  line-height: 10px;
  padding: 3px 4px 0;
}
.user-profile .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.user-profile .border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.user-profile .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

#main-wrapper[data-layout="vertical"],
#main-wrapper[data-layout="horizontal"] {
  /*This is for the sidebar bg*/
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"] {
  background: #fff;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .dark-logo,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .dark-logo {
  display: inline;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .light-logo,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .light-logo {
  display: none;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .nav-toggler,
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .topbartoggler,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .nav-toggler,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .topbartoggler {
  color: #7c8798;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin6"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin6"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin6"] {
  background: #f9fbfd;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] {
  background: #fff;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .list-divider,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .list-divider {
  background: #818181;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link {
  color: #818181;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  .feather-icon,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  i,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  .feather-icon,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  i {
  color: #9eabc0;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item.active
  > .sidebar-link.active,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item.active
  > .sidebar-link.active
  > i,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item.active
  > .sidebar-link.active,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .first-level
  .sidebar-item.active
  > .sidebar-link.active
  > i {
  color: #1c2d41;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  .feather-icon,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  i,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  .feather-icon,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.selected
  > .sidebar-link
  i {
  color: #fff;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .nav-small-cap,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .nav-small-cap {
  color: #000;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .has-arrow::after,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .has-arrow::after {
  border-color: #818181;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-content,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-name,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-email,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-content,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-name,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-email {
  color: #212529;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  li.selected
  > .has-arrow::after,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  li.selected
  > .has-arrow.active::after,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  li.selected
  > .has-arrow::after,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  li.selected
  > .has-arrow.active::after {
  border-color: #fff;
}

/*******************
Table Cell
*******************/
.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.table thead th,
.table th {
  font-weight: 500;
}

.nowrap {
  white-space: nowrap;
}

.v-middle td,
.v-middle th {
  vertical-align: middle;
}

.dataTables_wrapper {
  padding: 0;
}

/*******************
 Breadcrumb and page title
*******************/
.page-breadcrumb {
  padding: 30px 35px 0;
}
.page-breadcrumb .breadcrumb {
  background: transparent;
  font-size: 0.875rem;
}
.page-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #ced4da;
  font-size: 11px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item a {
  color: #8392a5;
}

.btn-circle,
.btn-circle-lg {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  line-height: 20px;
  padding: 10px;
}
.btn-circle .feather,
.btn-circle-lg .feather {
  height: 18px;
  width: 18px;
}

.btn-circle-lg {
  width: 57px;
  height: 57px;
  line-height: 35px;
}
.btn-circle-lg .feather {
  height: 22px;
  width: 22px;
}

.btn-rounded {
  border-radius: 2em;
}

.btn-list {
  margin-left: -8px;
  margin-bottom: -12px;
}
.btn-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

* {
  outline: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  color: #7c8798;
  background: #f9fbfd;
}

html {
  position: relative;
  min-height: 100%;
}

a.link {
  color: #7c8798;
}
a.link:hover,
a.link:focus {
  color: #5f76e8;
}

.svg-icon {
  height: 1.2em;
  width: 1.2em;
}

.set-doller {
  font-size: 17px;
  top: -9px;
  right: 2px;
}

.bg-transparent {
  background-color: transparent;
}

.custom-shadow {
  box-shadow: 0 2px 9px 0 rgba(169, 184, 200, 0.2);
  -webkit-box-shadow: 0 2px 9px 0 rgba(169, 184, 200, 0.2);
  -moz-box-shadow: 0 2px 9px 0 rgba(169, 184, 200, 0.2);
}

.custom-radius {
  border-radius: 60px;
}

.opacity-7 {
  opacity: 0.7;
}

html body .font-weight-medium {
  font-weight: 500;
}

html body .font-10 {
  font-size: 10px;
}

html body .font-12 {
  font-size: 12px;
}

html body .font-14 {
  font-size: 14px;
}

html body .font-16 {
  font-size: 16px;
}

html body .font-18 {
  font-size: 18px;
}

html body .font-20 {
  font-size: 20px;
}

/*******************
List-style-none
******************/
ul.list-style-none {
  margin: 0px;
  padding: 0px;
}
ul.list-style-none li {
  list-style: none;
}
ul.list-style-none li a {
  color: #7c8798;
  padding: 8px 0px;
  display: block;
  text-decoration: none;
}
ul.list-style-none li a:hover {
  color: #5f76e8;
}

b,
strong {
  font-weight: 500;
}

.f-icon,
.t-icon,
.w-icon,
.sl-icon,
.if-icon,
.m-icon {
  cursor: pointer;
  padding: 13px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.f-icon:hover,
.t-icon:hover,
.w-icon:hover,
.sl-icon:hover,
.if-icon:hover,
.m-icon:hover {
  background-color: #e8eaec;
}

.m-icon {
  width: 33%;
  display: inline-block;
}

@media (max-width: 767.98px) {
  .m-icon {
    width: 100%;
  }
}

.card,
.card-group {
  margin-bottom: 30px;
}

.card {
  box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  -webkit-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
  -moz-box-shadow: 0 3px 9px 0 rgba(169, 184, 200, 0.15);
}
.card .card-subtitle {
  font-weight: 300;
  margin-bottom: 10px;
  color: #9eabc0;
}
.card .card-title {
  position: relative;
  font-weight: 500;
  margin-bottom: 10px;
  color: #212529;
}

.custom-select {
  background: url(../../assets/images/custom-select.png) right 1.1rem center
    no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  background-size: auto;
}

.custom-select-set {
  height: 44px;
  line-height: 25px;
  padding: 10px 45px 13px 30px;
}

.dropdown-toggle::after {
  display: none;
}

.r-separator .form-group {
  border-bottom: 1px solid #edf2f9;
}
.r-separator .form-group:last-child {
  border-bottom: none;
}

.striped-rows .row:nth-child(odd) {
  background-color: #e8eaec;
}

.b-form .row {
  border-bottom: 1px solid #edf2f9;
  margin-bottom: 0;
  padding: 15px 0;
}
.b-form .row:last-child {
  border-bottom: none;
}

.b-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dropdown-menu {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

.modal-title {
  margin-top: 0;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: #fff;
  align-content: center;
  transform: translate(25%, 0) !important;
}
.modal-right button.close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.modal.show .modal-right {
  transform: translate(0, 0) !important;
}

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

.modal-colored-header {
  color: #fff;
  border-radius: 0;
}
.modal-colored-header .close {
  color: #fff !important;
}

.modal-filled {
  color: #fff;
}
.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-header,
.modal-filled .modal-footer {
  border: none;
}
.modal-filled .close {
  color: #fff !important;
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid rgba(108, 117, 125, 0.2);
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -2px;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid #5f76e8;
}

.popover-item {
  margin-left: -15px;
  border: 2px solid #fff;
}
.popover-item:hover {
  z-index: 10;
  position: relative;
}

.activity .border-left-line {
  border-left: 1px solid #edf2f9;
  position: relative;
  margin-left: 17px;
}
.activity .border-left-line .btn-item {
  z-index: 1;
  margin-left: -22px;
}
.activity .border-left-line:last-child {
  border-left: 0;
}

.chat-box {
  overflow: auto;
}

.chat-list .chat-item .chat-img {
  width: 45px;
  vertical-align: top;
}

.chat-list .chat-item .chat-content {
  width: calc(100% - 50px);
}
.chat-list .chat-item .chat-content .msg {
  background-color: #e4fbf8;
  font-size: 14px;
}

.chat-list .chat-item .chat-time {
  margin-left: 65px;
}

.chat-list .chat-item.odd .chat-content {
  width: calc(100% - 0px);
}
.chat-list .chat-item.odd .chat-content .msg {
  background-color: #eef5ff;
}

.chat-list .chat-item.odd .box {
  clear: both;
}

.chat-list .chat-item.odd + .odd {
  margin-top: 0px;
}

.chat-list .chat-item.reverse {
  text-align: right;
}
.chat-list .chat-item.reverse .chat-time {
  text-align: left;
}
.chat-list .chat-item.reverse .chat-content {
  padding-left: 0px;
  padding-right: 15px;
}

.grid-structure .grid-container {
  background-color: #e8eaec;
  margin-bottom: 10px;
  font-size: 0.8rem;
  padding: 10px 20px;
}

.auth-wrapper {
  min-height: 100vh;
}
.auth-wrapper .auth-box {
  /* box-shadow: 0px 3px 9px 0px rgba(162, 176, 190, 0.15); */
  max-width: 800px;
  margin: 5% 0;
  position: relative;
}
.auth-wrapper .auth-box .modal-bg-img {
  background-size: cover;
  background-position: center center;
  min-height: 300px;
}
.auth-wrapper #recoverform {
  display: none;
}

.bg-facebook {
  background-color: #3b5a9a;
}

.bg-twitter {
  background-color: #56adf2;
}

.landingpage-fonts {
  font-family: "TofinoPersonal-Regular", sans-serif;
  color: #616b7a;
}
.landingpage-fonts .font-tofino-regular {
  font-family: "TofinoPersonal-Regular";
}
.landingpage-fonts .font-tofino-book {
  font-family: "TofinoPersonal-Book";
}
.landingpage-fonts .font-tofino-medium {
  font-family: "TofinoPersonal-Medium";
}
.landingpage-fonts .font-gilmer-heavy {
  font-family: "Gilmer-Heavy";
}
.landingpage-fonts .font-gilmer-bold {
  font-family: "Gilmer-Bold";
}
.landingpage-fonts .font-gilmer-regular {
  font-family: "Gilmer-Regular";
}
.landingpage-fonts .font-gilmer-medium {
  font-family: "Gilmer-Medium";
}
.landingpage-fonts h1 {
  font-size: 39px;
}
.landingpage-fonts .page-wrapper {
  display: block;
  background: #fff;
}
.landingpage-fonts .navbar-light .navbar-nav .nav-link {
  color: #616b7a;
}
.landingpage-fonts .btn-secondary {
  background-color: #e9eef3;
  border-color: #e9eef3;
}
.landingpage-fonts .text-warning {
  color: #ffba00;
}
.landingpage-fonts .bg-light {
  background: #f9fbfd;
}
.landingpage-fonts .font-21 {
  font-size: 21px;
}
.landingpage-fonts .font-17 {
  font-size: 17px;
}
.landingpage-fonts h1,
.landingpage-fonts h2,
.landingpage-fonts h3,
.landingpage-fonts h4,
.landingpage-fonts h5,
.landingpage-fonts h6 {
  font-family: "Gilmer-Heavy", sans-serif;
  color: #2a3544;
}

.btn-custom {
  padding: 15px 31px;
  font-size: 17px;
  border-radius: 50px;
}

.img-shadow {
  box-shadow: 0px 0px 30px rgba(103, 119, 157, 0.3);
}

.btn-shadow {
  box-shadow: 0px 0px 20px rgba(103, 119, 157, 0.3);
}

.btn-shadow-v1 {
  box-shadow: 0px 3px 15px 0px rgba(95, 118, 232, 0.3);
}

.custom-lh {
  line-height: 35px;
}

.bg-banner {
  /* background: url(../../assets/images/landingpage/banner-bg.png) right 3% top
    no-repeat; */
  background-size: 636px 569px;
}

.bg-section {
  /* background: url(../../assets/images/landingpage/section-bg.png) left center
    no-repeat; */
  background-size: 764px 677px;
}

.bg-section-v2 {
  /* background: url(../../assets/images/landingpage/section-bg.png) center right
    26% no-repeat; */
  background-size: 598px 536px;
}

.feature-title {
  font-size: 39px;
  letter-spacing: -1px;
}

.topbar-v1 .header-banner {
  padding-top: 50px;
  padding-bottom: 120px;
}

.topbar-v2 .header-banner {
  padding-top: 50px;
  padding-bottom: 40px;
}

.overlay .overlay-buttons {
  position: absolute;
  height: 100%;
  background: rgba(31, 28, 46, 0.85);
  display: none;
  width: 100%;
  top: 0px;
  align-items: center;
  justify-content: center;
}
.overlay .overlay-buttons .custom-btn {
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 15px;
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  line-height: 23px;
}
.overlay .overlay-buttons .custom-btn:hover {
  opacity: 0.7;
}

.overlay:hover .overlay-buttons {
  display: flex;
}

.product-description {
  padding: 30px 30px 20px;
}

.list-icon {
  height: 19px;
  width: 19px;
}

.feature-list li {
  line-height: 35px;
}

.stroke-primary {
  stroke: #5f76e8;
}

.icon2 {
  /* background: url(../../assets/images/landingpage/icon-sprite.jpg) no-repeat;
  display: inline-block; */
}
.icon2.feature-icon {
  width: 50px;
  height: 50px;
}
.icon2.feature-icon.feature-icon1 {
  background-position: -11px -15px;
}
.icon2.feature-icon.feature-icon2 {
  background-position: -81px -15px;
}
.icon2.feature-icon.feature-icon3 {
  background-position: -155px -15px;
}
.icon2.feature-icon.feature-icon4 {
  background-position: -231px -15px;
}

.card-custom-shadow {
  box-shadow: 0px 2px 7px 0px rgba(42, 53, 68, 0.09);
}

@media (min-width: 768px) {
  .banner-img {
    margin-top: -100px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .live-box {
    padding: 30px;
    padding-bottom: 0;
  }
  .topbar-v2 .header-banner {
    padding-bottom: 0px;
  }
  .feature1,
  .header-banner {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .feature1,
  .header-banner {
    text-align: center;
  }
  .banner-img {
    max-width: 100%;
    height: auto;
  }
  .navbar-collapse {
    padding: 10px 0;
  }
  .navbar-collapse .navbar-nav .nav-item:last-child {
    padding-left: 0 !important;
  }
  .navbar-collapse .navbar-nav .nav-item:last-child .btn {
    width: 100%;
  }
  .topbar-v2 .header-banner {
    padding-bottom: 0px;
  }
  .brand-img {
    width: 142px;
  }
  .spacer {
    padding: 80px 0 0 0;
  }
  .btn-custom {
    padding: 17px 22px;
  }
  .feature-img {
    width: 100%;
  }
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: rgba(0, 0, 0, 0.2);
  height: 11px;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: rgba(0, 0, 0, 0.2);
  width: 11px;
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  bottom: 0;
  height: 15px;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
    -moz-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
    -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  bottom: 2px;
  height: 6px;
}

.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 6px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  opacity: 0;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  right: 0;
  width: 15px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
  -o-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  -moz-transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
    -moz-border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s linear,
    width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
    -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
  right: 2px;
  width: 6px;
}

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 6px;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-x
  > .ps-scrollbar-x-rail
  > .ps-scrollbar-x {
  background-color: rgba(0, 0, 0, 0.2);
  height: 6px;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-y
  > .ps-scrollbar-y-rail
  > .ps-scrollbar-y {
  background-color: rgba(0, 0, 0, 0.2);
  width: 6px;
}

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: rgba(0, 0, 0, 0.2);
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: transparent;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: rgba(0, 0, 0, 0.2);
}

.ps-container .ps-scrollbar-y-rail {
  position: absolute;
  /* please don't change 'position' */
  right: 3px;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  opacity: 0;
  filter: alpha(opacity=0);
  -o-transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
  transition: background-color 0.2s linear, opacity 0.2s linear;
}

.stats {
  height: 280px;
}
.stats .ct-series-a .ct-area {
  fill-opacity: 0.2;
  fill: transparent;
}
.stats .ct-series-a .ct-line,
.stats .ct-series-a .ct-point {
  stroke: #5f76e8;
  stroke-width: 3px;
}
.stats .ct-series-a .ct-point {
  stroke-width: 3px;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  border-radius: 20px;
  padding: 5px 20px;
  background: #5f76e8;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  font-weight: 500;
  font-size: 13px;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: #5f76e8;
}

.ct-label,
.c3-chart-arcs-title {
  color: #b8c3d5;
  fill: #b8c3d5;
}

#campaign-v2 .c3-chart-arc path {
  stroke-width: 4px;
}

#visitbylocate .jvectormap-zoomin,
#visitbylocate .jvectormap-zoomout {
  display: none;
}

.chartist-tooltip {
  position: relative;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-series-a .ct-bar,
.ct-series-a .ct-line {
  stroke: #5f76e8;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 15px 0 5px 0;
}
.fc-toolbar h2 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-weight: 600;
}

.fc-day {
  background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #f2f4f5;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #e8eaec;
}

.fc-button {
  background: #f2f4f5;
  border: none;
  color: #b8c3d5;
  text-shadow: none;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: #e9ecef;
}

.fc-state-highlight {
  background: #dee2e6;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #5f76e8;
  color: #fff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #dee2e6;
}

.fc-unthemed td.fc-today {
  background: #f8f9fa;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #e8eaec;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}

.fc-basic-view .fc-content {
  color: #fff;
}

.fc-time-grid-event .fc-content {
  color: #fff;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #e8eaec;
  border-radius: 50%;
  margin: 5px;
  font-size: 11px;
}

@media (max-width: 767.98px) {
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right,
  .fc-toolbar .fc-center {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc-today-button {
    display: none;
  }
}

@font-face {
  font-family: "TofinoPersonal-Medium";
  src: url("../css/fonts/TofinoPersonal-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../css/fonts/TofinoPersonal-Medium.otf") format("opentype"),
    url("../css/fonts/TofinoPersonal-Medium.woff") format("woff"),
    url("../css/fonts/TofinoPersonal-Medium.ttf") format("truetype"),
    url("../css/fonts/TofinoPersonal-Medium.svg#TofinoPersonal-Medium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TofinoPersonal-Regular";
  src: url("../css/fonts/TofinoPersonal-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../css/fonts/TofinoPersonal-Regular.otf") format("opentype"),
    url("../css/fonts/TofinoPersonal-Regular.woff") format("woff"),
    url("../css/fonts/TofinoPersonal-Regular.ttf") format("truetype"),
    url("../css/fonts/TofinoPersonal-Regular.svg#TofinoPersonal-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TofinoPersonal-Book";
  src: url("../css/fonts/TofinoPersonal-Book.eot?#iefix")
      format("embedded-opentype"),
    url("../css/fonts/TofinoPersonal-Book.otf") format("opentype"),
    url("../css/fonts/TofinoPersonal-Book.woff") format("woff"),
    url("../css/fonts/TofinoPersonal-Book.ttf") format("truetype"),
    url("../css/fonts/TofinoPersonal-Book.svg#TofinoPersonal-Book")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilmer-Heavy";
  src: url("../css/fonts/Gilmer-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../css/fonts/Gilmer-Heavy.otf") format("opentype"),
    url("../css/fonts/Gilmer-Heavy.woff") format("woff"),
    url("../css/fonts/Gilmer-Heavy.ttf") format("truetype"),
    url("../css/fonts/Gilmer-Heavy.svg#Gilmer-Heavy") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilmer-Regular";
  src: url("../css/fonts/Gilmer-Regular.eot?#iefix") format("embedded-opentype"),
    url("../css/fonts/Gilmer-Regular.otf") format("opentype"),
    url("../css/fonts/Gilmer-Regular.woff") format("woff"),
    url("../css/fonts/Gilmer-Regular.ttf") format("truetype"),
    url("../css/fonts/Gilmer-Regular.svg#Gilmer-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilmer-Medium";
  src: url("../css/fonts/Gilmer-Medium.eot?#iefix") format("embedded-opentype"),
    url("../css/fonts/Gilmer-Medium.otf") format("opentype"),
    url("../css/fonts/Gilmer-Medium.woff") format("woff"),
    url("../css/fonts/Gilmer-Medium.ttf") format("truetype"),
    url("../css/fonts/Gilmer-Medium.svg#Gilmer-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilmer-Bold";
  src: url("../css/fonts/Gilmer-Bold.eot?#iefix") format("embedded-opentype"),
    url("../css/fonts/Gilmer-Bold.otf") format("opentype"),
    url("../css/fonts/Gilmer-Bold.woff") format("woff"),
    url("../css/fonts/Gilmer-Bold.ttf") format("truetype"),
    url("../css/fonts/Gilmer-Bold.svg#Gilmer-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

#zero_config_previous {
  margin-top: 10px;
  color: #5f76e8;
  margin-right: 5px;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 15%;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ededed;
}
#zero_config_next {
  margin-top: 10px;
  color: #5f76e8;
  margin-right: 5px;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 15%;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #ededed;
}
.paginate_button {
  margin-top: 10px;
  color: #5f76e8;
  margin-right: 5px;
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 10px 15px;
  border-radius: 15%;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.current {
  color: #fff;
  margin-right: 5px;
  background-color: #5f76e8;
  border: 1px solid #ededed;
  padding: 10px 15px;
  border-radius: 15%;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

#zero_config_paginate {
  margin-bottom: 20px;
}

#zero_config_info {
  margin-bottom: -17px;
}

#zero_config_length {
  margin-bottom: -30px;
}
#zero_config_length select {
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#zero_config_filter input {
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #aee1e1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5f76e8;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

#auth {
  background: url(../../assets/images/big/auth-bg.jpg) no-repeat center center;
}

#modal {
  background-image: url(../../images/img-1.jpg);
  /* background-image: url(../../images/thumnail-for-codderlab.png); */
}

#pagination .MuiIconButton-root:hover {
  background: #5f73e3 !important;
  color: white;
}
@media only screen and (max-width: 600px) {
  #zero_config_info {
    margin-bottom: 15px;
  }
  #zero_config_length {
    margin-bottom: 0px;
  }
  progress {
    width: 100px;
  }
}
@media only screen and (max-width: 1024px) {
  #zero_config_info {
    margin-bottom: 15px;
  }
  #zero_config_paginate {
    margin-right: 100px;
  }
  /* #zero_config_length {
    margin-bottom: -30px;
  } */
}
@media (min-width: 1024px) and (max-width: 1400px) {
  #zero_config_paginate {
    margin-top: -30px;
  }
  #zero_config_length {
    margin-bottom: -30px;
  }
}

::-webkit-scrollbar {
  height: 0px; /* height of horizontal scrollbar ← You're missing this */
  width: 5px; /* width of vertical scrollbar */
  border: 1px solid #afbaf3;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #5f76e8;
}
